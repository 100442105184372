import { useEffect, useState } from 'react';
import { strings } from '../../lang';
import { Header } from '../header/header';
import './inbound-center.css';
import { Button, message, Modal, Tooltip } from 'antd';
import { OpportunityAssignDto, OpportunitySearchResult } from '../../dto/model';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  ShareAltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { bookingPageService } from '../../services/booking-page-service';
import Search from 'antd/es/input/Search';
import { SalesCallCalendar } from '../appointment-booking/sales-call-calendar';
import { useLocation, useNavigate } from 'react-router-dom';
const sfUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;

export const InboundCenter = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [searchResult, setSearchResult] = useState<
    OpportunitySearchResult[] | null
  >(null);
  const [selectedSearchEntry, setSelectedSearchEntry] =
    useState<OpportunitySearchResult | null>(null);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [isCancelButtonsDisabled, setIsCancelButtonsDisabled] = useState<
    boolean[] | null
  >(null);
  const [isCancelButtonsLoading, setIsCancelButtonsLoading] = useState<
    boolean[] | null
  >(null);
  const [isAssignButtonsDisabled, setIsAssignButtonsDisabled] = useState<
    boolean[] | null
  >(null);
  const [isAssignButtonsLoading, setIsAssignButtonsLoading] = useState<
    boolean[] | null
  >(null);
  const [isRescheduleButtonsDisabled, setIsRescheduleButtonsDisabled] =
    useState<boolean[] | null>(null);
  const [isRescheduleButtonsLoading, setIsRescheduleButtonsLoading] = useState<
    boolean[] | null
  >(null);
  const [isShareButtonsDisabled, setIsShareButtonsDisabled] = useState<
    boolean[] | null
  >(null);
  const [isShareButtonsLoading, setIsShareButtonsLoading] = useState<
    boolean[] | null
  >(null);
  const [isModalButtonDisabled, setIsModalButtonDisabled] = useState(false);
  const [isModalButtonLoading, setIsModalButtonLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [isMessageShown, setIsMessageShown] = useState(false);
  const isSuccess = location?.state?.isSuccess;

  useEffect(() => {
    if (!isMessageShown && isSuccess !== undefined) {
      if (isSuccess === true) {
        message.success(strings.inboundCallBack.success);
      } else {
        message.error(strings.inboundCallBack.failure);
      }
      setIsMessageShown(true);
    }
  }, [isSuccess, isMessageShown]);

  useEffect(() => {
    setSelectedSearchEntry(null);
    setIsCancelButtonsDisabled(null);
    setIsCancelButtonsLoading(null);
    setIsShareButtonsDisabled(null);
    setIsShareButtonsLoading(null);
    setIsAssignButtonsDisabled(null);
    setIsAssignButtonsLoading(null);
    setIsRescheduleButtonsDisabled(null);
    setIsRescheduleButtonsLoading(null);

    setIsRescheduling(false);
    return () => {
      setSearchResult(null);
      setIsCancelButtonsDisabled(null);
      setIsCancelButtonsLoading(null);
      setIsShareButtonsDisabled(null);
      setIsShareButtonsLoading(null);
      setIsAssignButtonsDisabled(null);
      setIsAssignButtonsLoading(null);
      setIsRescheduleButtonsDisabled(null);
      setIsRescheduleButtonsLoading(null);
    };
  }, []);

  const isEmail = (input: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const isPhoneNumber = (input: string): boolean => {
    const phoneRegex = /^(\+\d{1,3})?\d{8,}$/;
    return phoneRegex.test(input);
  };

  const isId = (input: string): boolean => {
    const phoneRegex = /^[a-zA-Z0-9]{15}([a-zA-Z0-9]{3})?$/;
    return phoneRegex.test(input);
  };

  const calculateHowLongCustomerHasBeenAdded = (creationDate: Date | null) => {
    const createdDate = creationDate ? new Date(creationDate) : null;
    if (createdDate) {
      const today = new Date();

      const timeDiff = Math.abs(today.getTime() - createdDate.getTime());
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysDiff <= 1) {
        return strings.signedupPeriod.today;
      } else if (daysDiff <= 2) {
        return strings.signedupPeriod.yesterday;
      } else if (daysDiff <= 7) {
        return strings.signedupPeriod.daysAgo.replace(
          '{0}',
          daysDiff.toString()
        );
      } else if (daysDiff <= 30) {
        return strings.signedupPeriod.weeksAgo.replace(
          '{0}',
          Math.floor(daysDiff / 7).toString()
        );
      } else if (daysDiff <= 90) {
        return strings.signedupPeriod.monthsAgo.replace(
          '{0}',
          Math.floor(daysDiff / 30).toString()
        );
      } else {
        return strings.signedupPeriod.moreThan3MonthsAgo;
      }
    }
  };

  const createSearchQuery = (input: string): string => {
    input = input.trim();
    if (/\s/.test(input)) {
      return `name=${input.split(' ')[0]}&lastName=${input
        .split(' ')
        .slice(1)
        .join(' ')}`;
    } else {
      if (isEmail(input)) {
        return `email=${input}`;
      } else if (isPhoneNumber(input)) {
        return `phone=${input}`;
      } else if (isId(input)) {
        return `id=${input}`;
      } else {
        return `lastName=${input}`;
      }
    }
  };

  const onCancelClick = async () => {
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const res = await bookingPageService.cancelAppointment(
      selectedSearchEntry!.externalAppointmentId!,
      selectedSearchEntry!.opportunityId,
      token.accessToken,
      false
    );
    if (res.isSuccess) {
      selectedSearchEntry!.appointmentStatus = 'Cancelled';
      selectedSearchEntry!.bookingStatus = 'ReschedulingNeeded';
    } else {
      message.error(res.errors?.join(' '));
    }
  };

  const changeLoadingStates = (
    state: boolean,
    buttonType?: string | undefined,
    index?: number | undefined
  ) => {
    if (index !== undefined) {
      if (buttonType === 'cancel') {
        setIsCancelButtonsLoading((prev) => {
          const newCancelButtonsLoading = [...prev!];
          newCancelButtonsLoading[index] = state;
          return newCancelButtonsLoading;
        });
      } else if (buttonType === 'share') {
        setIsShareButtonsLoading((prev) => {
          const newShareButtonsLoading = [...prev!];
          newShareButtonsLoading[index] = state;
          return newShareButtonsLoading;
        });
      } else if (buttonType === 'assign') {
        setIsAssignButtonsLoading((prev) => {
          const newAssignButtonsLoading = [...prev!];
          newAssignButtonsLoading[index] = state;
          return newAssignButtonsLoading;
        });
      } else if (buttonType === 'reschedule') {
        setIsRescheduleButtonsLoading((prev) => {
          const newRescheduleButtonsLoading = [...prev!];
          newRescheduleButtonsLoading[index] = state;
          return newRescheduleButtonsLoading;
        });
      }
    } else {
      setIsSearchLoading(state);
    }
  };

  const changeDisabledButtonsState = (
    state: boolean,
    buttonType?: string | undefined,
    index?: number | undefined
  ) => {
    if (index !== undefined && buttonType !== undefined) {
      setIsSearchDisabled(state);
      if (buttonType === 'cancel') {
        setIsCancelButtonsDisabled((prev) => {
          const newCancelButtonsDisabled = prev!.map((_, i) =>
            i === index ? prev![index] : state
          );
          return newCancelButtonsDisabled;
        });
        setIsShareButtonsDisabled((prev) => {
          const newShareButtonsDisabled = prev!.map((_, i) => state);
          return newShareButtonsDisabled;
        });
        setIsAssignButtonsDisabled((prev) => {
          const newAssignButtonsDisabled = prev!.map((_, i) => state);
          return newAssignButtonsDisabled;
        });
        setIsRescheduleButtonsDisabled((prev) => {
          const newRescheduleButtonsDisabled = prev!.map((_, i) => state);
          return newRescheduleButtonsDisabled;
        });
      } else if (buttonType === 'share') {
        setIsShareButtonsDisabled((prev) => {
          const newShareButtonsDisabled = prev!.map((_, i) =>
            i === index ? prev![index] : state
          );
          return newShareButtonsDisabled;
        });
        setIsAssignButtonsDisabled((prev) => {
          const newAssignButtonsDisabled = prev!.map((_, i) => state);
          return newAssignButtonsDisabled;
        });
        setIsCancelButtonsDisabled((prev) => {
          const newCancelButtonsDisabled = prev!.map((_, i) => state);
          return newCancelButtonsDisabled;
        });
        setIsRescheduleButtonsDisabled((prev) => {
          const newRescheduleButtonsDisabled = prev!.map((_, i) => state);
          return newRescheduleButtonsDisabled;
        });
      } else if (buttonType === 'assign') {
        setIsAssignButtonsDisabled((prev) => {
          const newAssignButtonsDisabled = prev!.map((_, i) =>
            i === index ? prev![index] : state
          );
          return newAssignButtonsDisabled;
        });
        setIsCancelButtonsDisabled((prev) => {
          const newCancelButtonsDisabled = prev!.map((_, i) => state);
          return newCancelButtonsDisabled;
        });
        setIsShareButtonsDisabled((prev) => {
          const newShareButtonsDisabled = prev!.map((_, i) => state);
          return newShareButtonsDisabled;
        });
        setIsRescheduleButtonsDisabled((prev) => {
          const newRescheduleButtonsDisabled = prev!.map((_, i) => state);
          return newRescheduleButtonsDisabled;
        });
      } else if (buttonType === 'reschedule') {
        setIsRescheduleButtonsDisabled((prev) => {
          const newRescheduleButtonsDisabled = prev!.map((_, i) =>
            i === index ? prev![index] : state
          );
          return newRescheduleButtonsDisabled;
        });
        setIsCancelButtonsDisabled((prev) => {
          const newCancelButtonsDisabled = prev!.map((_, i) => state);
          return newCancelButtonsDisabled;
        });
        setIsShareButtonsDisabled((prev) => {
          const newShareButtonsDisabled = prev!.map((_, i) => state);
          return newShareButtonsDisabled;
        });
        setIsAssignButtonsDisabled((prev) => {
          const newAssignButtonsDisabled = prev!.map((_, i) => state);
          return newAssignButtonsDisabled;
        });
      }
    } else {
      setIsCancelButtonsDisabled((prev) => {
        const newCancelButtonsDisabled = prev!.map((_, i) => state);
        return newCancelButtonsDisabled;
      });
      setIsShareButtonsDisabled((prev) => {
        const newShareButtonsDisabled = prev!.map((_, i) => state);
        return newShareButtonsDisabled;
      });
      setIsAssignButtonsDisabled((prev) => {
        const newAssignButtonsDisabled = prev!.map((_, i) => state);
        return newAssignButtonsDisabled;
      });
      setIsRescheduleButtonsDisabled((prev) => {
        const newRescheduleButtonsDisabled = prev!.map((_, i) => state);
        return newRescheduleButtonsDisabled;
      });
    }
  };

  const onSearch = async (value: string) => {
    if (value !== '') {
      changeLoadingStates(true);
      if (searchResult !== null) {
        changeDisabledButtonsState(true);
      }
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi
      );
      const localSearchResult = await bookingPageService.searchForOpportunities(
        token.accessToken,
        createSearchQuery(value)
      );
      setSearchResult(localSearchResult);

      setIsCancelButtonsDisabled(Array(localSearchResult.length).fill(true));
      setIsCancelButtonsLoading(Array(localSearchResult.length).fill(false));
      setIsShareButtonsDisabled(Array(localSearchResult.length).fill(true));
      setIsShareButtonsLoading(Array(localSearchResult.length).fill(false));
      setIsAssignButtonsDisabled(Array(localSearchResult.length).fill(true));
      setIsAssignButtonsLoading(Array(localSearchResult.length).fill(false));
      setIsRescheduleButtonsDisabled(
        Array(localSearchResult.length).fill(false)
      );
      setIsRescheduleButtonsLoading(
        Array(localSearchResult.length).fill(false)
      );

      changeLoadingStates(false);
      if (localSearchResult.length > 0) {
        changeDisabledButtonsState(false);
      }
    }
  };

  return (
    <>
      <Header
        centerComponents={
          <Search
            style={{ width: 608 }}
            onChangeCapture={(e) => {}}
            onSearch={onSearch}
            enterButton
            disabled={isSearchDisabled}
            loading={isSearchLoading}
            allowClear
            placeholder={strings.searchPlaceholder}
          />
        }
      />
      {searchResult && (
        <h3 style={{ marginLeft: 60 }}>
          {searchResult.length} Search Result
          {`${searchResult.length >= 2 ? 's' : ''}`}
        </h3>
      )}

      {searchResult &&
        searchResult.map((value, index) => (
          <div
            key={index}
            className="container-max-width inbound-container-border"
          >
            {value.assignedAgent && (
              <div
                style={{
                  padding: '20px 20px 0 20px',
                  fontSize: 'small',
                  color: '#EF4444',
                }}
              >
                {strings.attention(value.assignedAgent)}
              </div>
            )}
            <div className="flex-inbound-container" style={{ padding: 20 }}>
              <div className="inbound-grid-container">
                <div
                  className="bp-customer_icon_container bp-customer-title"
                  style={{ gridArea: '1 / 1 / 1 / 3' }}
                >
                  <UserOutlined />
                  {value.customerSalutation} {value.customerFirstName}{' '}
                  {value.customerLastName}
                  <div
                    className="inbound-booking-state"
                    style={{
                      backgroundColor: `${
                        value.bookingStatus === 'FirstTimeScheduling'
                          ? '#F0F0F0'
                          : value.bookingStatus === 'ReschedulingNeeded'
                          ? '#F6DBDB'
                          : '#DBF6DE'
                      }`,
                    }}
                  >
                    {value.bookingStatus === 'FirstTimeScheduling'
                      ? 'First-Time Scheduling'
                      : value.bookingStatus === 'ReschedulingNeeded'
                      ? 'Rescheduling Needed'
                      : value.bookingStatus}
                  </div>
                  {value.doNotCallBefore &&
                    dayjs(value.doNotCallBefore).toDate() > new Date() && (
                      <div
                        className="inbound-booking-state"
                        style={{
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        <Tooltip
                          title={dayjs(value.doNotCallBefore)
                            .local()
                            .format('D MMM YYYY h:mm A')}
                        >
                          <ClockCircleOutlined /> Snoozed
                        </Tooltip>
                      </div>
                    )}
                </div>
                <div
                  className="bp-customer_icon_container bp-customer-title"
                  style={{ gridArea: '2 / 2 / 2 / 2', width: 180 }}
                >
                  <a href={`tel:${value.phoneNumber}`}>
                    <PhoneOutlined /> {value.phoneNumber}
                  </a>
                </div>
                <div
                  className="bp-customer_icon_container"
                  style={{ width: 310, gridArea: '2 / 1 / 2 / 1' }}
                >
                  <CalendarOutlined />
                  {calculateHowLongCustomerHasBeenAdded(value.createdDate)}
                </div>
                <div
                  className="bp-customer_icon_container"
                  style={{ gridArea: '2 / 3 / 2 / 3' }}
                >
                  <MailOutlined /> <span>{value.email}</span>
                </div>
                <div
                  className="bp-customer_icon_container"
                  style={{ gridArea: '3 / 1 / 3 / 5' }}
                >
                  <EnvironmentOutlined />
                  {value.fullAddress}
                </div>
                {value.appointmentStartDate && (
                  <div
                    className="bp-customer_icon_container"
                    style={{ gridArea: '4 / 1 / 4 / 1' }}
                  >
                    <h5 style={{ margin: 0, padding: '15px 0 5px 0' }}>
                      {strings.appointmentDetails}
                    </h5>
                  </div>
                )}
                {value.appointmentStartDate && (
                  <div style={{ gridArea: '5 / 1 / 5 / 2' }}>
                    {value.appointmentStartDate && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          {dayjs(value.appointmentStartDate)
                            .local()
                            .format('D MMM YYYY')}{' '}
                          at{' '}
                          {dayjs(value.appointmentStartDate)
                            .local()
                            .format('h:mm A')}
                        </div>
                        <div
                          className="inbound-booking-state"
                          style={{
                            backgroundColor: '#F0F0F0',
                            color:
                              value.appointmentStatus === 'Cancelled'
                                ? '#B60000'
                                : '#007A2D',
                          }}
                        >
                          {value.appointmentStatus}
                        </div>
                      </div>
                    )}

                    {value.appointmentStartDate && (
                      <div>
                        {strings.salesManager}: {value.salesFirstName}{' '}
                        {value.salesLastName}
                      </div>
                    )}

                    {value.appointmentStartDate &&
                      value.appointmentStatus === 'Reserved' && (
                        <div style={{ marginTop: '5px' }}>
                          <Button
                            loading={isCancelButtonsLoading![index]}
                            disabled={isCancelButtonsDisabled![index]}
                            style={{ marginRight: '10px' }}
                            danger
                            type="primary"
                            onClick={async () => {
                              changeLoadingStates(true, 'cancel', index);
                              changeDisabledButtonsState(true, 'cancel', index);

                              setIsCancelClicked(true);
                              setSelectedSearchEntry(value);

                              changeLoadingStates(false, 'cancel', index);
                              changeDisabledButtonsState(
                                false,
                                'cancel',
                                index
                              );
                            }}
                          >
                            {strings.cancelAppointment}
                          </Button>
                          <Button
                            loading={isRescheduleButtonsLoading![index]}
                            disabled={isRescheduleButtonsDisabled![index]}
                            onClick={() => {
                              changeLoadingStates(true, 'reschedule', index);
                              changeDisabledButtonsState(
                                true,
                                'reschedule',
                                index
                              );
                              setIsRescheduling(true);
                              setSelectedSearchEntry(value);
                              changeLoadingStates(false, 'reschedule', index);
                              changeDisabledButtonsState(
                                false,
                                'reschedule',
                                index
                              );
                            }}
                          >
                            {strings.reschedule}
                          </Button>
                        </div>
                      )}
                  </div>
                )}
                <div className="inbound-main-btns">
                  <Button
                    loading={isShareButtonsLoading![index]}
                    disabled={isShareButtonsDisabled![index]}
                    icon={<ShareAltOutlined />}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          `${sfUrl}/lightning/r/Opportunity/${value.opportunityId}/view`
                        )
                        .then(() => {
                          message.success(strings.customerInfoCaseCopied);
                        })
                        .catch(() => {
                          message.error(strings.customerInfoFailedToCopy);
                        });
                    }}
                  >
                    {strings.share}
                  </Button>
                  <Tooltip
                    title={
                      value.bookingStatus.includes('OnHold') ||
                      value.planningStatus.includes('OnHold')
                        ? 'Planning or SC1 is on hold'
                        : ''
                    }
                  >
                    <Button
                      type="primary"
                      loading={isAssignButtonsLoading![index]}
                      onClick={async () => {
                        changeLoadingStates(true, 'assign', index);
                        changeDisabledButtonsState(true, 'assign', index);

                        const body: OpportunityAssignDto = {
                          opportunityId: value.opportunityId,
                          caseId: value.caseId,
                        };
                        const token = await GetAccessToken(
                          instance,
                          inProgress,
                          scopes.salesAppointmentApi
                        );
                        const response =
                          await bookingPageService.assignInboundOpportunity(
                            body,
                            token.accessToken
                          );

                        if (response.isSuccess) {
                          navigate('/booking', {
                            state: {
                              inboundCenter: true,
                            },
                          });
                        } else {
                          message.error(
                            'Please go offline in the booking queue to assign yourself a new customer'
                          );
                        }
                        changeLoadingStates(false, 'assign', index);
                        changeDisabledButtonsState(false, 'assign', index);
                      }}
                      disabled={
                        value.assignedAgent != null ||
                        value.bookingStatus === 'Scheduled' ||
                        value.bookingStatus === 'Reserved' ||
                        value.bookingStatus === 'CustomerReached' ||
                        value.planningStatus.includes('OnHold') ||
                        isAssignButtonsDisabled![index]
                      }
                    >
                      {strings.assignToMeAndOpen}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))}
      <Modal
        open={isRescheduling}
        width={'fit-content'}
        height={'fit-content'}
        onCancel={() => {
          setSelectedSearchEntry(null);
          setIsRescheduling(false);
        }}
        footer={null}
      >
        <div className="bp-calendar" style={{ minWidth: 700 }}>
          <SalesCallCalendar
            globalCustomerId={selectedSearchEntry?.globalCustomerId!}
            opportunityId={selectedSearchEntry?.opportunityId!}
            referrerId={null}
            sourceCaseId={selectedSearchEntry?.caseId!}
            displayHeader={false}
            isInbound={true}
            preBooking={async () => {
              const token = await GetAccessToken(
                instance,
                inProgress,
                scopes.salesAppointmentApi
              );
              const res = await bookingPageService.cancelAppointment(
                selectedSearchEntry!.externalAppointmentId!,
                selectedSearchEntry!.opportunityId,
                token.accessToken,
                true
              );

              if (res.isSuccess) {
                selectedSearchEntry!.appointmentStatus = 'Cancelled';
                selectedSearchEntry!.bookingStatus = 'ReschedulingNeeded';
              } else {
                message.error(res.errors?.join(' '));
              }
            }}
            onBooking={(isSuccess, bookedSlot, error) => {
              if (isSuccess) {
                selectedSearchEntry!.externalAppointmentId =
                  bookedSlot?.externalId;
                selectedSearchEntry!.appointmentStartDate =
                  bookedSlot?.startDate!;
                selectedSearchEntry!.bookingStatus = 'Scheduled';
                selectedSearchEntry!.salesFirstName =
                  bookedSlot?.salesManagerName!.split(' ')[0]!;
                selectedSearchEntry!.appointmentStatus = 'Reserved';
                selectedSearchEntry!.salesLastName = bookedSlot
                  ?.salesManagerName!.split(' ')
                  .slice(1)
                  .join(' ')!;
              } else {
                message.error(error);
              }
            }}
            outerModalClose={() => {
              setSelectedSearchEntry(null);
              setIsRescheduling(false);
            }}
            skipConfirmationAfterBooking={false}
          />
        </div>
      </Modal>
      {searchResult && (
        <Modal
          open={isCancelClicked}
          title={strings.cancelModal.confirmCancellation}
          onCancel={() => {
            setIsCancelClicked(false);
          }}
          okText={strings.cancelModal.confirmCancellation}
          cancelText={strings.cancelModal.rescheduleInstead}
          footer={[
            <Button
              key="1"
              disabled={isModalButtonDisabled}
              onClick={() => {
                setIsCancelClicked(false);
                setIsRescheduling(true);
              }}
            >
              {strings.cancelModal.rescheduleInstead}
            </Button>,
            <Button
              key="2"
              type="primary"
              loading={isModalButtonLoading}
              onClick={async () => {
                setIsModalButtonDisabled(true);
                setIsModalButtonLoading(true);
                await onCancelClick();
                setIsModalButtonLoading(false);
                setIsModalButtonDisabled(false);
                setIsCancelClicked(false);
              }}
            >
              {strings.cancelModal.confirmCancellation}
            </Button>,
          ]}
        >
          <li>{strings.cancelModal.text}</li>
        </Modal>
      )}
    </>
  );
};
